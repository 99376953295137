<template>
    <div class="container">
        <div class="border-container">
            <div class="info-container">
                <span class="list-title">测评名额
                    <span class="residue">{{residueNumber}}</span>
                </span>
                <el-button type="primary" plain @click="handleDialogShow">
                    <i class="iconfont icon-recharge_order_management"></i> 充值
                </el-button>
            </div>
        </div>
        <div class="border-container">
            <div class="info-container">
                <span class="list-title">充值记录</span>
            </div>
            <div class="table-container">
                <el-table :data="rechargeList" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                    <el-table-column label="充值名额" min-width="150" align="center">
                        <template slot-scope="scope">
                            {{scope.row.increased_count }}
                        </template>
                    </el-table-column>
                    <el-table-column label="充值金额" min-width="150" align="center">
                        <template slot-scope="scope">
                            {{scope.row.money }}
                        </template>
                    </el-table-column>
                    <el-table-column label="剩余名额" min-width="150" align="center">
                        <template slot-scope="scope">
                            {{scope.row.count }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作人" min-width="150" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">
                            {{scope.row.name || ''}}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background
                    @size-change="pageSizeChange"
                    @current-change="pageChange"
                    :current-page="currentPage"
                    :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="充值测试名额" :visible.sync="dialogVisible" @close="handleDialogClose" class="school-record-dialog">
            <div class="dialog-content">
                <div class="content-floor">
                    <span class="key">名额数量</span>
                    <el-input v-model.number="rechargeForm.count"></el-input>
                </div>
                <div class="content-floor">
                    <span class="key">金额(元)</span>
                    <el-input v-model.number="rechargeForm.money"></el-input>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button type="primary" size="medium" @click="handleConfirm">确定</el-button>
                <el-button class="cancel" size="medium" @click="handleDialogClose">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from 'core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: String
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            dialogVisible: false,
            multipleSelection: [],
            rechargeList: [],
            rechargeForm: {
                count: '',
                money: '',
            },
            residueNumber: '',
        };
    },
    created() {
        this.getTableList();
        this.getResidueNumber();
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableList();
        },
        getTableList() {
            Core.Api.SchoolRecord.list(
                this.orgId,
                this.currentPage,
                this.pageSize,
            ).then((res) => {
                this.total = res.count;
                this.rechargeList = res.list
            });
        },
        getResidueNumber() {
            Core.Api.School.detail(this.orgId).then((res) => {
                this.residueNumber = res.school.record_invitation_count;
            });
        },
        routerChange(key, q = '') {
            switch (key) {
                case 1:
                    // this.$router.push('');
                    break;
            }
        },
        handleDialogShow() {
            this.dialogVisible = true;
        },
        handleDialogClose() {
            this.dialogVisible = false;
            this.rechargeForm = {
                count: '',
                money: '',
            }
        },
        handleConfirm() {
            if (!this.rechargeForm.count) {
                return this.$message.warning('名额个数不可为空');
            }
            if (!this.rechargeForm.money) {
                return this.$message.warning('金额不可为空');
            }
            Core.Api.SchoolRecord.create(
                this.orgId,
                this.rechargeForm.count,
                this.rechargeForm.money,
            ).then((res) => {
                this.$message.success('充值成功');
                this.handleDialogClose();
                this.getTableList();
                this.getResidueNumber();
            }).catch(err => {
                console.log("handleConfirm -> err", err)
            })
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    .border-container:first-child {
        margin-bottom: 20px;
        .info-container {
            border-bottom: 0;
        }
    }
    @import '@/common/styles/table.scss';
    .info-container {
        @include flex(row, space-between, center);
        .residue {
            display: inline-block;
            padding: 0 10px;
            border-radius: 4px;
            margin-left: 30px;
            background-color: rgba(30, 181, 78, .13);
            color: #1EB54E;
            border: 0;
            height: 24px;
            font-size: 14px;
            line-height: 24px;
        }
    }
    .school-record-dialog {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
</style>